import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useHolidaysList(){

    const toast = useToast()

    const refHolidayListTable = ref(null)
    
    // Holidays table contains column Holiday Code / Holiday Name / Status / Created at / Action
    const tableColumns = [
        { key: 'id', sortable: true },
        { key: 'holiday_date', sortable: true },
        { key: 'holiday_name', sortable: true },
        { key: 'actions'}
    ]
    const perPage = ref(10)
    const totalHolidays = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refHolidayListTable.value ? refHolidayListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1: 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalHolidays.value,
        }
    })

    const refetchData = () =>{
        refHolidayListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
        refetchData()
    })

    const fetchHolidays = (ctx, callback) => {

        store.dispatch('setting-holiday/fetchHolidays', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
        })
        .then(response => {
            const { holidays, total } = response.data
            callback(holidays)
            totalHolidays.value = total
        })
        .catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching holidays list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                },
            })
        })

    }

    return {
        fetchHolidays,
        tableColumns,
        perPage,
        currentPage,
        totalHolidays,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refHolidayListTable,
        ability

    }


}